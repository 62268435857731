:root {
	/* // Basic */
	--c-white: #fff;
	--c-black: #000;

	/* // Greys */
	--c-ash: #eaeef6;
	--c-charcoal: #a0a0a0;
	--c-void: #141b22;

	/* // Beige/Browns */
	--c-fair-pink: #FFEDEC;
	--c-apricot: #FBC8BE;
	--c-coffee: #754D42;
	--c-del-rio: #917072;

	/* // Greens */
	--c-java: #1FCAC5;

	/* // Purples */
	--c-titan-white: #f1eeff;
	--c-cold-purple: #a69fd6;
	--c-indigo: #6558d3;
	--c-governor: #4133B7;
}

/* Add application styles & imports to this file! */
/* Source : https://uigradients.com/#BetweenNightandDay */
body {
  /* background: linear-gradient(to right, #d9dce0, #dde3e7); */
  background-color: var(--c-ash);
  font-family: Montserrat, Verdana, Geneva, Tahoma, sans-serif;
  padding: 0;  
  margin: 0;
}

h1, p {
	font-family: Montserrat, Verdana, Geneva, Tahoma, sans-serif;
  }

[type="button"], [type="submit"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	border-radius: 4px;
	/* appearance: none; */
}

/*
select {
	height: 3.6875rem;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 1px solid #cacaca;
	border-radius: 4px;
	background-color: #fefefe;
	font-family: inherit;
	font-size: 1.25rem;
	font-weight: 400;
	color: #121212;
	background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZlcnNpb249JzEuMScgd2lkdGg9JzMyJyBoZWlnaHQ9JzI0JyB2aWV3Qm94PScwIDAgMzIgMjQnPjxwb2x5Z29uIHBvaW50cz0nMCwwIDMyLDAgMTYsMjQnIHN0eWxlPSdmaWxsOiByZ2IoMTM4LCAxMzgsIDEzOCknPjwvcG9seWdvbj48L3N2Zz4=);
	background-origin: content-box;
	background-position: right -1.875rem center;
	background-repeat: no-repeat;
	background-size: 9px 6px;
	padding: .9375rem 2.8125rem .9375rem .9375rem;
	transition: box-shadow .5s,border-color .25s ease-in-out;
}
*/
/*
[type="color"], [type="date"], [type="datetime-local"], [type="datetime"], [type="email"], [type="month"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], textarea {
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 3.6875rem;
	padding: .9375rem;
	border: 1px solid #cacaca;
	border-radius: 4px;
	background-color: #fefefe;
	box-shadow: inset 0 1px 2px rgba(18,18,18,.1);
	font-family: inherit;
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.5;
	color: #121212;
	transition: box-shadow .5s,border-color .25s ease-in-out;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
*/