

.container {
  box-sizing: border-box;
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.kwInputContainer{
  box-sizing: border-box;  
  border-radius: 4px;
  /* align-self: center; */
  padding: 20px 10px;
  background-color: #26282c;
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.kwInputForm {
  padding: 0;
  margin: 0;
  width: 90%;
  /* font-size: 2rem; */
  /* display: flex;
  flex-direction: row;
  align-items: flex-start; */
}

.search__field {
  width: 350px;
  padding: 17px 20px;   
}
.search__language .search__region {
  width: 15%;
  outline: none;
  padding: 12px 20px;  
}

.search__field, .search__language, .search__region {
  margin-right: 20px;
  height: 48px;
  font-size: 16px; 
  color: #202020;
  background-color: rgb(206, 202, 202);
  appearance: none;
  cursor: pointer;  
}

.optionSelect.button {
  background-color: greenyellow !important;
  color: black;
}

.search__submit, .extended__submit, .csv__submit, .logout {
	width: 148px;
	height: 48px;
  margin-right: 10px;
	background: #f16434;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	letter-spacing: .08em;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	border: 1px solid transparent;
	font-family: Geomanist Book,sans-serif;
}

.logout {
  background:red;
}

.kwList{
  margin-top: 15px;
  /* padding-left: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.kwUl{
  padding: 0;

}
.kwListItem{
  color: white;
  list-style-type: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-left: 10px;
}

